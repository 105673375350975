@charset "utf-8";
/* ==============================
   Small desktop
   ============================== */

@media only screen and (max-width: 1366px) {


}

@media only screen and (max-width: 1200px) {


}


@media only screen and (max-width: 1199px) {

}

/* ==============================
   Tablet landskape
   ============================== */
@media only screen and (max-width: 1024px) {


}

@media only screen and (max-width: 992px) {


}

/* ==============================
   Tablet Portrait
   ============================== */
@media only screen and (max-width: 768px) {


}

/* ==============================
   Phone
   ============================== */
@media only screen and (max-width: 575px) {


}

@media only screen and (max-width: 380px) {


}


/* ==============================
   Small Phone
   ============================== */
@media only screen and (max-width: 319px) {


}

@media not all and (min-resolution:.001dpcm) { @media {
    .e-filter-wrap label{
      top: 7px;
   position: relative;
    }
}}