@charset "utf-8";

/*------------------------------------------------------------------

1. Login

-------------------------------------------------------------------*/
.e-login-logo-wrap{
	background: #FFFFFF;
	padding: 15px;
}
.e-login-logo-wrap img{
	max-width: 100px
}
.e-login-section{
	background: linear-gradient(90deg, rgba(27, 97, 182, 0.1) 0%, rgba(74, 175, 57, 0.1) 100%);
	height: 100vh;
}
.e-login-wrap{
	background: #FFFFFF;
	box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
	border-radius: 3px;
	padding: 30px;
}
.e-forgot-link{
	top: -20px !important;
	position: relative;
	font-size: 14px;
}
.e-copyright-wrap{
	width: 100%;
	font-size: 12px;
	position: absolute;
	left: 15px;
	bottom: 15px;
}
.e-copyright-wrap a{
	font-weight: 600;
}

/*------------------------------------------------------------------

2. Navigations

-------------------------------------------------------------------*/
.e-logout{
	color: #334D6E;
	font-weight: 600;
	font-size: 13px;
	line-height: 18px;
	padding-left: 20px;
	background-image: url("../images/logout.svg");
	background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 12px;
  cursor: pointer;
}
.e-sidebar-wrap{
	height: 100vh;
	background: #FFFFFF;
	box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
	z-index: 1;
}
.e-sidebar-logo{
	border-bottom: 1px solid #EBEFF2;
	padding: 14px 0;
}
.e-sidebar-logo img{
	max-width: 100px;
}
.e-sidebar-profile-wrap img{
	border-radius: 50%;
	width: 40px;
	height: 40px;
	object-fit: cover;
}
.e-sidebar-profile-wrap h5{
	font-weight: 600;
	font-size: 14px;
	color: #192A3E;
}
.e-sidebar-profile-wrap .e-email{
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.01em;
	color: #90A0B7;
}
.e-sidebar-item a{
	padding-left: 30px;
	letter-spacing: 0.01em;
	background-position: 0 3px;
  background-repeat: no-repeat;
  background-size: 18px;
  font-weight: 600;
	font-size: 14px;
	color: #737373;
	display: block;

	-webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.e-sidebar-item a:hover, .e-sidebar-item a.e-active{
	color: #1B61B6;
}
.e-sidebar-creators{
	background-image: url("../images/sidebar/creators-icon.svg");
}
.e-sidebar-creators:hover, .e-sidebar-creators.e-active{
	background-image: url("../images/sidebar/creators-icon-blue.svg");
}
.e-sidebar-users{
	background-image: url("../images/sidebar/users-icon.svg");
	background-size: 20px !important; 
	background-position: 0 0px !important;
}
.e-sidebar-users:hover, .e-sidebar-users.e-active{
	background-image: url("../images/sidebar/users-icon-blue.svg");
}
.e-sidebar-categories{
	background-image: url("../images/sidebar/categories-icon.svg");
	background-size: 18px !important; 
	background-position: 0 0px !important;
}
.e-sidebar-categories:hover, .e-sidebar-categories.e-active{
	background-image: url("../images/sidebar/categories-icon-blue.svg");
}
.e-sidebar-courses{
  background-image: url("../images/sidebar/courses-icon.svg");
  background-size: 18px;
  background-position: 0 0px !important;
}
.e-sidebar-courses:hover, .e-sidebar-courses.e-active{
  background-image: url("../images/sidebar/courses-icon-active.svg");
}
.e-sidebar-profile{
  background-image: url("../images/sidebar/profile-icon.svg");
  background-size: 18px !important;
  background-position: 0 0px !important;
}
.e-sidebar-profile:hover, .e-sidebar-profile.e-active{
  background-image: url("../images/sidebar/profile-icon-active.svg");
}


/*------------------------------------------------------------------

3. Creators List

-------------------------------------------------------------------*/
.e-filter-wrap label{
	font-size: 12px;
	color: #6A707E;
	letter-spacing: 0.01em;
	display: inline-block;
}
/*.e-creators-wrap .e-filter-wrap{
	position: absolute;
	top: -14px;
}*/
.e-search-input{
	background: transparent;
	width: 140px;
	position: absolute;
	right: 15px;
	top: -20px;
	border-bottom: 0;
	padding-bottom: 6px;
	letter-spacing: 0.01em;
	background-position: 0 29px;
	background-repeat: no-repeat;
	background-size: 14px;
	padding-left: 25px;
  background-image: url("../images/search-icon.svg");
  font-size: 14px;
}
.e-search-input:focus, .e-search-input:active, .e-search-input:valid{
	width: 280px;
	border-bottom: 1px solid #1B61B6;
	background-image: url("../images/search-icon-blue.svg");
}
.e-search-input::-webkit-input-placeholder {
  font-weight: 600;
	font-size: 12px;
	color: #90A0B7;	
}
.e-search-input:-ms-input-placeholder {
  font-weight: 600;
	font-size: 12px;
	color: #90A0B7;	
}
.e-search-input::placeholder {
	font-weight: 600;
	font-size: 12px;
	color: #90A0B7;	
}
.e-creator-pic{
	width: 30px;
	height: 30px;
	border-radius: 50%;
	object-fit: cover;
}
.e-creator-name{
	font-weight: 600;
	font-size: 15px;
	letter-spacing: 0.01em;
	color: #323C47;
}
.e-detail-link .e-creator-name{
  color: #1B61B6;
}
.e-detail-link .e-creator-name:hover{
  color: #323C47;
}
.e-creator-dull-txt{
	font-size: 13px;
	letter-spacing: 0.01em;
	color: #707683;
	line-height: 30px;
}
.e-channel-link a{
	font-size: 13px;
	letter-spacing: 0.01em;
	color: #1B61B6;
	line-height: 30px;

	-webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.e-channel-link a:hover{
	color: #323C47;
}
.e-more-icon{
	background-position: 0 5px;
	background-repeat: no-repeat;
	background-size: 12px;
	background-image: url("../images/more-icon.svg");
	display: inline-block;
	width: 20px;
	height: 18px;
	position: absolute;
	right: 0px;
	top: 15px;
	cursor: pointer;

	-webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.e-more-icon:hover{
	background-image: url("../images/more-icon-blue.svg");
}
.e-creator-item-more{
	background: #FFFFFF;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
	letter-spacing: 0.01em;
	color: #1B61B6;
	font-weight: bold;
	font-size: 12px;
	display: inline-block;
	position: absolute;
	right: 15px;
	top: 30px;
	z-index: 2;
	min-width: 150px;
	text-align: center;
	cursor: pointer;

	-webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.e-creator-item-more:hover{
	color: #323C47;
}
.e-mute-txt{
	padding: 15px;
	display: inline-block;
}
.e-creator-item-more .e-api-loader{
	padding: 30px 15px;
}
.e-more-overlay{
	background: rgba(255, 255, 255, 0.75);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.e-muted .e-creator-dull-txt, .e-muted .e-channel-link, .e-muted .e-creator-pic, .e-muted .e-creator-name{
	opacity: .4;
}
.e-load-more{
	padding: 10px 15px;
	background: #fff;
	font-size: 12px;
	font-weight: 700; 
	cursor: pointer;
	border: 2px solid #1B61B6;
	color: #1B61B6;
	cursor: pointer;

	-webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.e-load-more:hover{
	border: 2px solid #323C47;
	color: #323C47;
}


/*------------------------------------------------------------------

4. Invites List

-------------------------------------------------------------------*/
.e-invites-wrap button{
	font-size: 13px;
}
.e-status-invited span{
	background: #FFB946;
	border-radius: 4px;
	font-size: 11px;
	letter-spacing: 0.02em;
	color: #FFFFFF;
	padding: 4px 10px;
	display: inline-block;
	min-width: 100px;
	text-align: center;
}
.e-status-accepted span{
	background: #2ED47A;
	border-radius: 4px;
	font-size: 11px;
	letter-spacing: 0.02em;
	color: #FFFFFF;
	padding: 4px 10px;
	display: inline-block;
	min-width: 100px;
	text-align: center;
}


/*------------------------------------------------------------------

5. Users List

-------------------------------------------------------------------*/
.e-download-link{
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.01em;
	color: #1B61B6;
	background-position: 0;
	background-repeat: no-repeat;
	background-size: 14px;
	background-image: url("../images/download-icon.svg");
	padding-left: 20px;
	cursor: pointer;

	-webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.e-download-link:hover{
	color: #323C47;
	background-image: url("../images/download-icon-dark.svg");
}
.e-download-loader .e-lazy-loader{
	display: inline-block;
	width: 60px;
	height: 15px;
	background-position: 0;
	background-size: cover;
}



/*------------------------------------------------------------------

6. Categories List

-------------------------------------------------------------------*/
.e-lang-item, .e-lang-btn-wrap{
	background: #FFFFFF;
	box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
	border-radius: 3px;
	padding: 20px 15px;
	font-weight: 600;
	font-size: 13px;
	line-height: 18px;
	color: #000000;
	cursor: pointer;
}
.e-lang-btn-wrap{
	display: none;
	text-align: center;
}
.e-lang-item-wrap:hover .e-lang-item{
	display: none;
}
.e-lang-item-wrap:hover .e-lang-btn-wrap{
	display: block;
	box-shadow: 10px 6px 18px rgba(0, 0, 0, 0.15);
}

/*------------------------------------------------------------------

7. Creator detail

-------------------------------------------------------------------*/
.e-back-btn{
	letter-spacing: 0.01em;
	color: #1B61B6;
	font-weight: 700;
	font-size: 13px;
	line-height: 18px;
	background-position: 0 3px;
	background-repeat: no-repeat;
	background-size: 11px;
	background-image: url("../images/back-icon.svg");
	padding-left: 20px;
	display: block;
	cursor: pointer;

	-webkit-transition: all .3s ease;
	transition: all .3s ease;
}
.e-back-btn:hover{
	color: #323C47;
	background-image: url("../images/back-icon-dark.svg");
}
.e-detail-profile-wrap{
	background: #FFFFFF;
	width: 100%;
	padding: 15px;
  border-radius: 5px;
}
.e-detail-profile-wrap img{
	width: 60px;
	height: 60px;
	border-radius: 50%;
	object-fit: contain;
}
.e-detail-profile-top h5{
	font-weight: 600;
	font-size: 24px;
	letter-spacing: 0.01em;
	color: #323C47;
}
.e-detail-profile-top .e-email{
	font-weight: normal;
	font-size: 13px;
	line-height: 18px;
	letter-spacing: 0.01em;
	color: #707683;
}
.e-detail-profile-top{
  border-radius: 5px;
}
.e-p-link-txt, .e-s-link-txt{
	letter-spacing: 0.01em;
	color: #474747;
	font-size: 13px;
	line-height: 18px;
	font-weight: 600;
	display: inline-block;
	cursor: pointer;

	-webkit-transition: all .3s ease;
	transition: all .3s ease;
}
.e-p-link-txt{
	color: #1B61B6;
}
.e-s-link-txt{
	color: #474747;
}
.e-p-link-txt:hover{
	color: #474747;
}
.e-s-link-txt:hover{
	color: #1B61B6;
}
.e-detail-profile-bottom{
	padding-top: 15px;
}
.e-detail-profile-counts{
	font-weight: 600;
	font-size: 18px;
	line-height: 25px;
	letter-spacing: 0.01em;
	color: #4AAF39;
}
.e-detail-profile-item{
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.01em;
	color: #707683;
}
.e-detail-profile-wrap .e-channel-link a{
	cursor: pointer;
}
.e-detail-profile-counts-wrap{
	display: inline-block;
}
.e-detail-profile-activity{
	padding-top: 30px;
	font-size: 12px;
	line-height: 18px;
	text-align: right;
	letter-spacing: 0.01em;
	color: #707683;
	display: inline-block;
}
.e-detail-profile-activity span{
	opacity: .6;
}
.e-bank-note{
  background: #fff;
  padding: 30px;
}
.e-bank-note p{
  padding-top: 10px;
  font-weight: 600;
}
.e-detail-tab-wrap span{
	font-weight: 600;
	font-size: 18px;
	line-height: 25px;
	color: #192A3E;
	opacity: 0.5;
	display: inline-block;
	cursor: pointer;

	-webkit-transition: all .3s ease;
	transition: all .3s ease;
}
.e-detail-tab-wrap span.e-active, .e-detail-tab-wrap span:hover{
	opacity: 1;
}
.e-detail-tab-wrap{
	border-bottom: 1px solid #EBEFF2;
	padding-bottom: 15px;
}
.e-video-card-wrap img{
	height: 150px;
	width: 100%;
	object-fit: cover;
}
.e-video-card-wrap h5{
	font-weight: 600;
	font-size: 13px;
	line-height: 18px;
	color: #000000;
}
.e-video-card-wrap .card-body{
	padding: 15px;
}
.e-video-card-wrap .card{
	background: #FFFFFF;
	box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
	border: none;
	min-height: 245px;
}
.e-video-card-wrap .e-card-time{
	font-weight: 600;
	font-size: 11px;
	line-height: 15px;
	color: #000000;
	opacity: 0.5;
	margin-bottom: 0;
}
.e-video-card-wrap .e-video-duration{
	background: #000000;
	mix-blend-mode: normal;
	opacity: 0.85;
	border-radius: 0px 3px 0px 0px;
	color: #FFFFFF;
	display: inline-block;
	font-weight: 600;
	font-size: 11px;
	line-height: 15px;
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 5px 15px;
}
.e-video-card-wrap .e-video-live{
	position: absolute;
	bottom: 15px;
	right: 15px;
	padding: 5px 10px;
	font-weight: 600;
	font-size: 11px;
	line-height: 12px;
	color: #FFFFFF;
	background: #FF0303;
	border-radius: 10px;
}
.e-profile-placeholder{
	width: 100%;
	height: 188px;
}
.e-card-delete, .e-card-edit, .e-card-link{
	position: absolute;
	right: 15px;
	bottom: 15px;
	width: 24px;
	height: 24px;
	display: inline-block;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 16px;
	background-image: url("../images/delete-icon.svg");
	cursor: pointer;

	-webkit-transition: all .3s ease;
	transition: all .3s ease;
}
.e-card-delete:hover{
	background-image: url("../images/delete-icon-blue.svg");
}
.e-card-edit{
	background-image: url("../images/edit-icon.svg");
	right: 30px;
}
.e-card-edit:hover{
	background-image: url("../images/edit-icon-blue.svg");
}
.e-card-link{
	background-image: url("../images/link-icon.svg");
}
.e-card-link:hover{
	background-image: url("../images/link-icon-blue.svg");
}
.e-add-video-select-wrap{
	position: relative;
}
.e-add-video-select-wrap label{
	position: absolute;
	left: 0px;
	top: -3px;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.01em;
	color: #818E9B;
  /*margin-bottom: 0;*/

	-webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.e-add-video-select-wrap .css-2b097c-container{
	border-bottom: 1px solid #D3D8DD;
	width: 100% !important;
}
.e-add-video-select-wrap .css-1pahdxg-control, .e-add-video-select-wrap .css-yk16xz-control{
	padding-top: 11px;
  border: none;
}
.e-add-video-select-wrap .css-1okebmr-indicatorSeparator{
  display: none;
}

.e-modal .e-add-video-select-wrap .css-tlfecz-indicatorContainer{
  /*position: absolute;*/
  /*right: 0;
  top: -10px;
  position: relative;*/
}
.e-add-video-select-wrap .css-yk16xz-control{
	width: 100% !important;
}
/*.e-add-video-select-wrap .css-tlfecz-indicatorContainer, .e-add-video-select-wrap .css-1gtu0rj-indicatorContainer{
	display: none !important;
}*/
.e-add-video-select-wrap .css-1uccc91-singleValue{
	color: #000 !important; 
	font-size: 14px !important;
	padding-left: 0 !important;
	margin-left: 0 !important;
}

.rc-time-picker-panel-column-3{
  top: 475px !important;
}
.e-upload-btn{
	background: #F5F6F8;
	border-radius: 3px;
	height: 120px;
	padding-top: 45px;
	cursor: pointer;
	-webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.e-upload-btn span{
	font-weight: 500;
	font-size: 11px;
	line-height: 15px;
	letter-spacing: 0.01em;
	color: #818E9B;
}
.e-upload-btn:hover{
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  border-color: transparent;
  box-shadow: 0 0 0 0 rgba(0,0,0,.1), 0 1px 3px 0 rgba(0,0,0,.1), 0 4px 15px 0 rgba(0,0,0,.15);
}
.e-upload-main-btn{
  background-image: url("../images/e-upload-icon.svg");
  background-size: 18px;
  background-position: center 15px;
  background-repeat: no-repeat;
}
.e-addPromo-btn-wrap {
	padding-top: 80px;
}
.rc-time-picker-clear-icon{
	opacity: 0;
}
.e-uploader-loader{
  display: inline-block;
  width: 18px;
  height: 18px;
  background-size: cover;
  background-image: url("../images/upload-loader.gif");
}
.e-image-preview-wrap{
	padding: 5px;
}
.e-image-preview-wrap img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.e-add-video-select-wrap .css-1hwfws3{
	min-width: 120px;
}
.e-upcoming-delete{
	position: absolute;
	top: 0;
	background-color: #fff;
	right: 0;
	padding: 15px;
	box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
}
.css-1rhbuit-multiValue{
	position: relative !important;
	display: inline-block !important;
}
.css-12jo7m5{
	padding-right: 30px !important;
}
.css-xb97g8{
	position: absolute;
	right: 2px;
	top: 3px;
}

/*------------------------------------------------------------------

8. Courses List

-------------------------------------------------------------------*/
.e-course-item{
  border-radius: 10px;
  background-color: #fff;
  min-height: 320px;
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
}
.e-course-item img{
  width: 100%;
  max-height: 130px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}
.e-course-item .e-course-details{
  padding: 15px;
}
.e-course-item .e-course-details h5{
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
}
.e-course-item .e-course-details .e-category-tag{
  background: #c2d6ea85;
  display: inline-block;
  border-radius: 10px;
  padding: 2px 8px;
  font-size: 12px;
  border: 1px solid #e8e8e8;
  margin-bottom: 5px;
  margin-right: 5px;
  opacity: .6;
}
.e-course-item .e-course-details .text-dull{
  font-size: 12px;
  opacity: .4;
  margin-bottom: 2px;
}
.e-users{
  display: inline-block;
  margin-right: 20px;
  padding-top: 40px;
  background-image: url("../images/users.svg");
  background-position: 0;
  background-size: 18px;
  background-repeat: no-repeat;
}
.e-course-figures p{
  font-size: 14px;
  margin-bottom: 0;
}
.e-course-figures p b{
  color: #1B61B6;
}
.e-calendar-wrap .DateInput__small{
	border-radius: 5px !important;
	width: 97px !important;
}

.e-calendar-wrap .DateInput_input__small{
	padding: 10px 7px 8px 7px !important;
	font-size: 12px !important;
	/*border-radius: 5px !important;*/
}
/*Course detail*/
.e-video-detail-wrap{
  background-color: #fff;
  border-radius: 10px;
}
.e-video-detail-cover{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 0px 0px 10px;
}
.e-creators-wrap .e-video-detail-cover{
  max-height: 250px;
}
.e-video-summary{
  padding: 15px 15px 15px 0;
}
.e-video-detail-wrap .e-category-tag{
  background: #c2d6ea85;
  display: inline-block;
  border-radius: 10px;
  padding: 2px 8px;
  font-size: 12px;
  border: 1px solid #e8e8e8;
  margin-bottom: 5px;
  margin-right: 5px;
  opacity: .6;
}
.e-video-detail-wrap .text-dull{
  font-size: 12px;
  opacity: .4;
  margin-bottom: 2px;
  display: inline-block;
}
.e-video-detail-wrap .e-course-figures p{
  font-size: 16px;
}
.e-video-detail-wrap .e-course-figures p b{
  color: #1B61B6;
}
.e-video-detail-wrap .e-edit-btn{
  position: absolute;
  right: 30px;
}
.e-tab-head-wrap span{
  font-size: 18px;
  font-weight: 800;
  margin-right: 30px;
  opacity: .4;
  display: inline-block;
  cursor: pointer;
  border-bottom: 2px solid transparent;

  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.e-tab-head-wrap span:hover{
  opacity: .6;
}
.e-tab-head-wrap span.e-active{
  opacity: 1;
  border-bottom: 2px solid #000;
}
.e-tab-content{
  background: #fff;
  border-radius: 5px;
  padding: 15px 30px;
}
.e-tab-content .text-dull{
  font-size: 12px;
  opacity: .4;
  margin-bottom: 2px;
  display: inline-block;
}
.e-tab-content h6{
  font-size: 18px;
  font-weight: 600;
}
.e-module-item{
  border-bottom: 1px solid #f1f1f1;
  padding: 15px 0;
}
.e-module-item .e-title, .e-module-item .e-time{
  padding-left: 30px;
  background-image: url("../images/play.svg");
  background-position: 0;
  background-size: 18px;
  background-repeat: no-repeat;
  display: block;
  padding-bottom: 0;
  color: #000;
  opacity: .7;
}
.e-module-item .e-time{
  background-image: url("../images/time.svg");
}
.e-module-subject-wrap{
  padding: 8px 0;
  cursor: pointer;

  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.e-module-subject-wrap:hover p{
  color: #1B61B6;
}
.e-module-subject-wrap:hover .e-time{
  background-image: url("../images/time-active.svg");
}
.e-module-subject-wrap:hover .e-title{
  background-image: url("../images/play-active.svg");
}
.e-add-module{
  padding: 15px;
  border: 1px dotted #ccc;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}
.e-add-module:hover{
  background-color: #f1f1f1;
  color: #1B61B6;
}
.rdw-inline-wrapper .rdw-option-wrapper:nth-child(4), 
.rdw-inline-wrapper .rdw-option-wrapper:nth-child(5),
.rdw-inline-wrapper .rdw-option-wrapper:nth-child(6), 
.rdw-inline-wrapper .rdw-option-wrapper:nth-child(7),
.rdw-block-wrapper .rdw-dropdown-wrapper,
.rdw-fontfamily-wrapper .rdw-dropdown-wrapper,
.rdw-colorpicker-wrapper .rdw-option-wrapper,
.rdw-link-wrapper .rdw-option-wrapper,
.rdw-embedded-wrapper .rdw-option-wrapper,
.rdw-image-wrapper .rdw-option-wrapper,
.rdw-emoji-wrapper .rdw-option-wrapper,
.rdw-remove-wrapper .rdw-option-wrapper,
.rdw-history-wrapper .rdw-option-wrapper,
.rdw-dropdown-carettoopen, .rdw-dropdown-carettoclose{
  display: none;
}
.rdw-dropdown-selectedtext{
  width: 30px !important;
}
.rdw-editor-main{
  border: 1px solid #f1f1f1;
  min-height: 200px;
  padding: 30px;
}
.e-students-tab table{
  box-shadow: none;
  border: 1px solid #f1f1f1;
}
.e-students-tab table th{
  background-color: #f1f1f1;
  font-size: 14px;
}
.e-chart-summary-item,{
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 5px;
}
.e-chart-summary-item h5{
  font-size: 16px;
}
.e-chart-summary-item h5 .e-viewers{
  color: #68e397;
}
.e-chart-summary-item h5 .e-enrolled{
  color: #2a8ff7;
}
.e-rating-summary-item{
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 5px;
}
.e-rating-summary-item h5{
  font-size: 16px;
}
.e-rating-summary-item h5 b{
  display: block;
  font-size: 22px;
  margin-top: 10px;
}
.e-rating-summary-item h5 .e-rating{
  color: #1B61B6;
}
.e-review-item img{
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.e-review-item h5{
  font-size: 16px;
  font-weight: 600;
}
.e-review-item .e-review-content{
  padding-right: 40px;
  opacity: .7;
}
.e-rate-star{
  display: inline-block;
  font-size: 16px;
  color: #1B61B6;
  margin-left: 30px;
  line-height: 0;
}
.e-review-wrap{
  background-color: #f1f1f1;
  padding: 20px 30px 15px 30px;
}
.e-review-item{
  border-bottom: 1px solid #ccc;
}
.e-review-item:last-child{
  border-bottom: 0;
}


/*Profile Detail*/
.e-profile-item{
  border-radius: 5px;
  background-color: #fff;
  padding: 15px;
  min-height: 210px;
}
.e-profile-item h6{
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
  padding-bottom: 10px;
  opacity: .6;
}
.e-profile-item .e-detail-profile-wrap{
  padding: 0;
}
.e-profile-item .e-detail-profile-top{
  border-bottom: 0;
}
.e-profile-item .e-detail-profile-wrap img{
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.e-profile-item .e-edit-btn{
  background-position: 8px;
  background-size: 10px;
  background-repeat: no-repeat;
  padding: 1px 6px 1px 24px;
  font-size: 11px;
}
.e-profile-item  .text-dull{
  font-size: 12px;
  opacity: .4;
  margin-bottom: 2px;
}
.e-profile-item .e-edit-btn{
  position: absolute;
  right: 30px;
  top: 15px;
}
.e-profile-courses table{
  box-shadow: none;
}


/*Create course modal*/
.e-modal .modal-header{
  border-bottom: 1px solid #ccc;
}
.e-modal h5{
  font-size: 20px;
  padding-bottom: 10px;
}
.e-modal .css-yk16xz-control, .e-modal .css-1pahdxg-control, .e-modal .css-1pahdxg-control:hover{
  border: none !important;
}
.e-select-multi .css-tlfecz-indicatorContainer:first-child, .e-select-multi .css-1gtu0rj-indicatorContainer:first-child{
  display: none !important;
}
.e-select-multi .css-yk16xz-control, .e-select-multi .css-1pahdxg-control{
  width: 100% !important;
  max-width: 96% !important;
}

.e-modal .css-2b097c-container .css-1hb7zxy-IndicatorsContainer{
  position: absolute;
  right: -25px;
  top: 15px;
}
.e-final-price-wrap{
  opacity: .8;
  cursor: not-allowed;
}
.e-final-price{
  cursor: not-allowed;
  background-color: #f1f1f1;
}
.e-course-descp-loader .e-lazy-loader{
  width: 60px;
  height: 60px;
}

/*Create module modal */
.e-add-module-modal{
  max-width: 850px;
  width: 850px;
}
.e-module-details-wrap{
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 30px;
}
.e-module-details-wrap .e-input-wrap{
  margin-bottom: 15px;
}
.e-video-upload-wrap label{
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: #818E9B;
  transition: all .3s ease;
  text-align: left;
  display: block;
}
.e-video-upload-wrap .e-video-upload-btn{
  background-color: #f1f1f1;
  padding: 15px;
  text-align: left;
  background-image: url("../images/e-upload-icon.svg");
  background-position: 96%;
  background-size: 12px;
  background-repeat: no-repeat;
  color: #737373;
}
.e-video-upload-btn-inital{
  cursor: pointer;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}
.e-video-upload-wrap .e-video-upload-btn-inital:hover{
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}
.e-module-details-wrap .e-add-video-select-wrap .css-1hwfws3{
  padding-left: 0;
}
.e-module-details-wrap .css-2b097c-container .css-1hb7zxy-IndicatorsContainer{
  right: -5px;
}
.e-video-upload-btn.e-upload-loader{
  background-image: url("../images/lazyloader-2.gif");
  background-position: center;
  background-size: 80px;
  background-repeat: no-repeat;
  padding: 25px;
}
.e-video-upload-btn.e-uploaded{
  background-image: none;
  font-size: 12px;
}
.e-video-upload-btn.e-uploaded span{
  display: inline-block;
  background-color: #fff;
  border-radius: 5px;
  padding: 2px 6px;
  position: absolute;
  right: 45px;
}
.e-video-upload-btn.e-uploaded span:hover{
  color: #1B61B6;
  cursor: pointer;
}
.e-delete-link{
  text-transform: uppercase;
  background-image: url("../images/delete-icon-red.svg");
  background-position: 0px 9px;
  background-repeat: no-repeat;
  background-size: 16px;
  padding: 10px 10px 10px 20px;
  color: #D60B0B;
}
.e-delete-link:hover{
  background-image: url("../images/delete-icon-blue.svg");
}
.e-add-module-modal .e-addPromo-btn-wrap{
  padding-top: 0;
}
.e-note, .e-video-error{
  font-size: 13px;
  margin-bottom: 15px;
}

/*Video player modal*/
.e-video-modal h5{
  font-size: 16px;
  padding-top: 0 !important;
}

.e-video-modal video{
  width: 100%;
}
.e-video-modal .modal-dialog{
  max-width: 650px;
}
/*Edit Profile Modal*/
.e-edit-profile-modal .e-upload-main-btn{
  padding: 60px 15px 15px;
  line-height: 0;
  background-position: center 25px;
}
.e-video-url-ellipse{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.e-change{
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #818E9B;
  position: absolute;
  right: 0;
  top: 0;
  background: #f1f1f1;
  padding: 5px;
  cursor: pointer;
}
.e-detail-head{
  padding-right: 70px;
}
.e-text-wrap label{
  background-color: #ffff;
  width: 100%;
}
.e-create-course-wrap .css-tlfecz-indicatorContainer{
  top: -15px;
  position: absolute;
  right: 15px;
}
.e-create-course-wrap .css-1hwfws3{
  padding-top: 15px;
}
.e-saved-tag{
	background: #c2d6ea85;
    display: inline-block;
    border-radius: 10px;
    padding: 2px 8px;
    font-size: 12px;
    border: 1px solid #e8e8e8;
    margin-bottom: 5px;
    margin-right: 5px;
    opacity: .6;
}
.e-video-delete{
	position: absolute;
    float: right;
    top: 10px;
    right: 10px;
    z-index: 1;
    width: 10px;
    height: 30px;
}
.e-edit-btn-padding{
	padding: 5px 12px 5px 25px!important;
	text-align: right;
}
/*------------------------------------------------------------------

14. Invoice Listing

-------------------------------------------------------------------*/
.e-invoice-wrap .e-calendar-wrap{
  padding-top: 30px;
}
.e-summary-item{
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
}
.e-summary-item .e-dull-text{
  font-size: 12px;
  opacity: .7;
}
.e-summary-item p{
  margin-bottom: 0;
  font-weight: 800;
  font-size: 20px;
}

/*-------------------------------------------------------------------

15. Invoice detail

-------------------------------------------------------------------*/
.e-detail-invoice-wrap h5{
  font-weight: 600;
}
.e-detail-invoice-wrap h6{
  display: block;
  font-weight: 800;
}
.e-detail-invoice-wrap .modal-dialog{
  max-width: 800px;
}
.e-detail-invoice-wrap h4{
  font-weight: 800;
  font-size: 16px;
  margin-bottom: 20px;
}
.e-price-table-header{
  border-bottom: 1px solid #ccc;
}
.e-price-table-item{
  border-bottom: 1px solid #ccc;
}
.e-price-table-total{
  border-top: 1px solid #ccc;
}
.e-price-table-total h6, .e-price-table-total p{
  font-size: 16px;
}
.e-price-table-fully-paid{
  display: inline-block;
  border: 2px solid #3ba229;
  padding: 8px 15px;
  border-radius: 5px;
  color: #3ba229;
  position: absolute;
  right: 30px;
  bottom: 10px;
  transform: rotate(-20deg);
  font-weight: 800;
}
.e-detail-invoice-wrap .e-s-btn{
  position: absolute;
  left: 30px;
  bottom: 30px;
}
.e-detail-invoice-wrap .close{
  position: absolute;
  right: 15px;
  z-index: 1;
  top: 15px;
}
.e-invoice-logo{
  max-width: 100px;
}
.e-detail-invoice-wrap .modal-header{
  border-bottom: 0;
  padding: 0px;
}
.e-detail-invoice-wrap .modal-content{
  padding: 30px 15px;
}
.e-image-preview-wrap img{
	max-height: 120px;
	padding: 0px;
	margin: 0px;
}
.e-video-url-ellipse{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.e-video-error{
	font-size: 12px !important;
}

.e-video-note{
	font-size: 12px !important;
	font-color: blue;
}
.e-creator-requests-table .e-link-text{
  font-size: 14px;
}
.e-saved-tag{
  background: #c2d6ea85;
    display: inline-block;
    border-radius: 10px;
    padding: 2px 8px;
    font-size: 12px;
    border: 1px solid #e8e8e8;
    margin-bottom: 5px;
    margin-right: 5px;
    opacity: .6;
}
.e-video-delete{
  position: absolute;
    float: right;
    top: 10px;
    right: 10px;
    z-index: 1;
    width: 10px;
    height: 30px;
}
.e-edit-btn-padding{
  padding: 5px 12px 5px 25px!important;
  text-align: right;
}
.e-module-video-wrap .e-module-details-wrap{
  margin-bottom: 20px;
}
.e-tab-item-title{
  font-size: 20px;
  margin-right: 60px;
  opacity: .6;
  font-weight: 600;
  cursor: pointer;
}
.e-tab-item-title:hover{
  opacity: .8;
}
.e-tab-item-title.e-active{
  color: #000;
  border-bottom: 3px solid #1B61B6;
  opacity: 1;
}
.table .e-link-text{
	color: #1B61B6;
  cursor: pointer;
  transition: all .3s ease;
}
.table .e-link-text:hover{
	text-decoration: underline;
}