@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:    "Trade School Admin - Alice Blue webinar management app"
Version:    1.0
Created:    "21-02-2020"
Use:   "Admin panel"
-------------------------------------------------------------------*/


/*------------------------------------------------------------------
[Table of contents]

1. Import Fonts & Icnons
2. Colors
3. Page loader / .page-loader
4. Global styles
5. Buttons and CTA
6. Forms fields
7. Animations

-------------------------------------------------------------------*/

/*------------------------------------------------------------------

1. Import Fonts & Icnons

-------------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css?family=Nunito:400,600,700&display=swap');


/*------------------------------------------------------------------

2. Colors

-------------------------------------------------------------------*/




/*------------------------------------------------------------------

3. Page loader / .page-loader

-------------------------------------------------------------------*/
.e-api-loader{
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url("../images/lazyloader-2.gif");
	width: 100px;
	height: 24px;
	display: inline-block;
}
.e-lazy-loader{
  display: inline-block;
  width: 140px;
  height: 140px;
  background-size: contain;
  background-image: url("../images/lazyloader-2.gif");
}


/*------------------------------------------------------------------

4. Global styles

-------------------------------------------------------------------*/
*, *:focus{
	outline: none !important;
}
body{
	font-family: Nunito;
	font-style: normal;
	overflow: hidden;
}
h2{
	font-weight: 600;
	font-size: 18px;
	color: #192A3E;
}
section{
	background: #F5F6F8;
}
header{
	padding: 20px;
	background: #FFFFFF;
}
.e-main-content{
	padding: 30px;
	overflow-y: scroll;
	height: 94vh;
}
.e-main-content h2{
  position: absolute;
  top: 20px;
  z-index: 999;
}
a{
	color: #000;
}
a.e-card:hover{
  color: initial;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.e-card:hover{
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}
.e-link-txt{
  color: #1B61B6;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
}
.e-link-txt:hover{
  color: #000;
}
.text-danger, .text-success{
	font-size: 13px;
}

/*Table*/
.table{
	background: #fff;
	border-radius: 5px;
	box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
}
.table thead th{
	font-weight: 600;
	font-size: 13px;
	color: #334D6E;
	opacity: 0.5;
	border-top: 0;
	padding: 15px;
	border-bottom: 1px solid #EBEFF2;
}
.table tbody td{
	border-bottom: 1px solid #EBEFF2;
}

/*Nothing Found*/
.e-no-result-wrap{
  height: 70vh;
}
.e-no-result-wrap img{
  width: 200px;
  margin-bottom: 40px;
}

/*Scroll bar*/
::-webkit-scrollbar{width: 0px;}
::-webkit-scrollbar-track{background: #ccc;}
::-webkit-scrollbar-thumb{background: #2e42b6;}
::-webkit-scrollbar-thumb:hover{background: #555;}


/*Modal*/
.modal-body p{
	font-size: 13px;
	line-height: 18px;
	letter-spacing: 0.01em;
	color: #4C5862;
}
.modal-header h5{
	font-weight: 600;
	font-size: 15px;
	line-height: 20px;
	letter-spacing: 0.01em;
	color: #323C47;
}
.modal-header{
	padding: 15px 15px 0px 15px;
	border-bottom: 0;
}
.modal-header button{
	box-shadow: none;
	font-size: 18px !important;
	font-weight: 100 !important;
}
.modal-footer{
	padding: 0px 15px 15px 15px;
	border-top: none;
}
.modal-open .modal{
  background: rgba(255, 255, 255, 0.9);
}
.modal.show .modal-content{
	background: #FFFFFF;
  box-shadow: 0px 0px 16px rgba(117, 117, 117, 0.1);
	border-radius: 4px;
	border: none !important;
}
.e-modal-s-txt{
	display: block;
	padding: 10px 15px 0;
	font-size: 13px;
	line-height: 18px;
	letter-spacing: 0.01em;
	color: #4C5862;
}

/*ellipsis*/
.e-p-ellipsis-2{
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  height: 35px;
  line-height: 30px;
}
.e-p-ellipsis-1{
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  height: 20px;
  line-height: 18px;
}


/*Date Picker*/
.DateInput_input__focused{
	padding: 0px;
}
.SingleDatePickerInput__withBorder{
	border: 0 !important;
  border-bottom: 1px solid #D3D8DD !important;
  width: 100% !important;
}
.e-datePicker-wrap{
	position: relative;
	padding-top: 10px;
}
.SingleDatePicker{
	width: 100% !important;
}
.DateInput{
	width: 100% !important;
}
.e-datePicker-wrap label{
	position: absolute;
	left: 0px;
	top: 0px;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.01em;
	color: #818E9B;

	-webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.DateInput_input{
	font-size: 14px !important;
  padding: 11px 0px 0px !important;
}
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover{
	background: #1B61B6 !important;
	border: 1px double #1B61B6 !important;
}

/*Time Picker*/
.e-timePicker-wrap {
	position: relative;
}
.e-timePicker-wrap label{
	position: absolute;
	left: 0px;
	top: 0px;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.01em;
	color: #818E9B;

	-webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.rc-time-picker{
	margin-top: 20px;
	width: 100% !important;
}
.rc-time-picker-input{
	border: 0 !important;
  border-bottom: 1px solid #D3D8DD !important;
  width: 100% !important;
  border-radius: 0 !important;
  padding: 0 !important;
}
.rc-time-picker-panel{
  top: 400px !important;
}
.rc-time-picker-panel-inner{
	font-size: 14px;
	border: none !important;
}
.rc-time-picker-input{
	font-size: 14px !important;
	color: #000 !important;
}

.e-expected-timePicker .rc-time-picker-panel{
  top: 600px !important;
  position: absolute !important;
}


/*------------------------------------------------------------------

5. Forms fields

-------------------------------------------------------------------*/
input, textarea{
	box-shadow: none;
	border: none;
	width: 100%;
	border-bottom: 1px solid #D3D8DD;
	padding-top: 25px;
	font-size: 14px;

	-webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.e-input-wrap{
	position: relative;
	display: block;
	margin-bottom: 30px;
}
input ~ label, textarea ~ label{
	position: absolute;
	left: 0px;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.01em;
	color: #818E9B;

	-webkit-transition: all .3s ease;
  transition: all .3s ease;
}
input:focus, textarea:focus{
	border-bottom: 1px solid #1B61B6;
}
input:focus ~ label, textarea:focus ~ label{
	color: #1B61B6;
}
input [type=file], #e-logo-upload{
	width: 1px;
	height: 1px;
	opacity: 0;
	padding: 0;
	margin: 0;
	position: absolute;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.e-price-input{
  background-image: url("../images/rupee.svg");
  background-position: 95%;
  background-size: 16px;
  background-repeat: no-repeat;
}

button{
	width: initial;
	padding: 10px 20px;
	background: #1B61B6;
	box-shadow: 0px 4px 10px #D7E0EA;
	border-radius: 4px;
	color: #FFFFFF;
  border: none;

	-webkit-transition: .3s linear;
  transition: .3s linear;
}
button:hover{
	-webkit-transform: scale(.92);
  transform: scale(.92);
  box-shadow: none;
}
button.e-inactive{
	opacity: .5;
	cursor: not-allowed !important;
}
button.e-inactive:hover{
	-webkit-transform: scale(1);
  transform: scale(1);
  box-shadow: 0px 4px 10px #D7E0EA;
}

button.e-s-btn{
  background-color: #FFFFFF;
  border: 1px solid #1B61B6;
  color: #1B61B6;
  padding: 9px 19px;
}
button::-moz-focus-inner {
  border: 0 !important;
  outline: 0 !important
}
.e-edit-btn{
  background-image: url("../images/edit-icon-blue.svg");
  background-position: 8px;
  background-size: 10px;
  background-repeat: no-repeat;
  padding: 2px 10px 2px 30px;
  font-size: 12px;
}
 button.e-p-btn-s{
  padding: 7px 12px;
  font-size: 12px;
 }


/*Select box*/
/*.css-yk16xz-control, .css-1pahdxg-control{
	background-color: transparent !important;
	border: none !important;
	font-size: 12px;
	display: inline-block !important;
	box-shadow: none !important;
}
.css-2b097c-container{
	display: inline-block !important;
}
.css-1okebmr-indicatorSeparator{
	display: none;
}
.css-1hb7zxy-IndicatorsContainer, .css-1hwfws3{
	display: inline-block !important;
}
.css-1hwfws3{
	min-width: 100px;
	top: 8px;
	padding: 2px 3px !important;
}
.css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer{
	padding: 5px 0 !important;
	position: absolute;
	top: 4px;
	right: -15px;
}
.css-1uccc91-singleValue{
	color: #1B61B6 !important;
}
.css-6q0nyr-Svg{
	width: 15px;
}
.css-26l3qy-menu{
	min-width: 120px !important;
	font-size: 12px !important;
	background: #FFFFFF;
	box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
	border-radius: 0 !important;
	border: none !important;
}*/
.e-filter-label{
  font-size: 12px;
}
.css-26l3qy-menu{
  min-width: 120px !important;
  font-size: 12px !important;
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
  background: #FFFFFF;
  border: none !important;
}
.css-yk16xz-control, .css-1pahdxg-control{
  font-size: 12px;
  box-shadow: none !important;
  max-width: 200px;
}
.hidden{
	display: none !important;
}
.e-c-red{ color: red; }
.hand{ cursor: pointer; }
.hand:hover{color: #1B61B6;}
/*------------------------------------------------------------------

6. Animations

-------------------------------------------------------------------*/